import { ILogger, LoggerType } from '@tr-digital/app-logger/dist/types';
import { buildAppLogger } from '@tr-digital/app-logger';
import { getEnvVar } from '../appEnvVariables';

let appLogger: ILogger;

export const initAppLogger = (): ILogger => {
  let loggerType = getEnvVar('loggerType');
  let loggerConfig;

  const loggersArray = loggerType?.split(',');
  const isValidLoggerType = loggersArray?.some((type) =>
    ['datadog', 'appDynamics', 'client'].includes(type)
  );
  if (!isValidLoggerType) {
    loggerType = 'client';
  }
  const appDynamicsConfig = { key: getEnvVar('appDynamicsKey') };

  const datadogConfig = {
    applicationId: getEnvVar('datadogApplicationId'),
    clientToken: getEnvVar('datadogClientToken'),
    site: getEnvVar('datadogSite'),
    service: getEnvVar('datadogService'),
    env: getEnvVar('datadogEnv'),
  };

  const loggerConfigs = {
    appDynamics: appDynamicsConfig,
    datadog: datadogConfig,
    client: {},
  };
  loggersArray?.forEach((loggerType) => {
    loggerConfig = {
      ...loggerConfig,
      ...loggerConfigs[loggerType as LoggerType],
    };
  });

  const logger = buildAppLogger(loggerType, loggerConfig as any);
  return logger;
};

export const getAppLogger = () => {
  if (!appLogger) {
    appLogger = initAppLogger();
  }

  return appLogger;
};
