import { IEnvVariables, IRecaptchaEnvVars } from '../types';
import { defaultEnvVars } from '../Constants';
import { getRecaptchaToken } from '@tr-digital/fetch';

export const NODE_ENV = process.env.NODE_ENV;
export const IS_PROD = NODE_ENV === 'production';
export const IS_TEST = NODE_ENV === 'test';
export const IS_DEV = NODE_ENV === 'development' || !NODE_ENV;

export interface ITransactEnvVariables extends IEnvVariables {
  storeId: string;
  orgId: string;
  merchantId: string;
  fingerPrintScriptSource: string;
  dcpUnifiedManipulationAPI: string;
  ciamClientId: string;
  tokenURL: string;
  authorizeURL: string;
  refreshTokenURL: string;
  practicalLawLeadCaptureUrl: string;
  defaultPlanDuration: string;
  site?: string;
  defaultNumberOfAttorneys: string;
  fetchTaxEndpoint: string;
  isDeferredLogin: string;
  organizationAppType: string;
  fetchOrganizationTypeEndpoint: string;
  disableRegexPattern: string;
  allowedRegexExpression: string;
  enableSo: string;
  boomiPaymetricDecryptEndpoint: string;
  orgTypeDetailsEndpoint: string;
  highqSuccessPage: string;
  configurationName: string;
  applicationId: string;
  companyId: string;
  currency: string;
  simulateOrderExtensibleAttributes: string;
  datadogClientToken: string;
  datadogSite: string;
  datadogApplicationId: string;
  datadogService: string;
  datadogEnv: string;
  DCPCommonLeadService: string;
  onePassLoginUrl: string;
  onePassCreateUrl: string;
  onePassProductId: string;
  onePassPageUiOption: string;
  isOnePassEnabled: string;
  idpDiscoveryEndpoint: string;
}

export const defaultEnvVariables = {
  ...defaultEnvVars,
  storeId: '',
  orgId: '',
  merchantId: '',
  fingerPrintScriptSource: '',
  dcpUnifiedManipulationAPI: '',
  ciamClientId: '',
  tokenURL: '',
  authorizeURL: '',
  refreshTokenURL: '',
  practicalLawLeadCaptureUrl: '',
  defaultPlanDuration: '1',
  defaultNumberOfAttorneys: '1',
  fetchTaxEndpoint: '',
  isDeferredLogin: '',
  organizationAppType: '',
  fetchOrganizationTypeEndpoint: '',
  disableRegexPattern: '',
  allowedRegexExpression: '',
  enableSo: '',
  boomiPaymetricDecryptEndpoint: '',
  orgTypeDetailsEndpoint: '',
  highqSuccessPage: '',
  configurationName: '',
  applicationId: '',
  companyId: '',
  currency: '',
  simulateOrderExtensibleAttributes: '',
  datadogClientToken: '',
  datadogSite: '',
  datadogApplicationId: '',
  datadogService: '',
  datadogEnv: '',
  DCPCommonLeadService: '',
  onePassLoginUrl: '',
  onePassCreateUrl: '',
  onePassProductId: '',
  onePassPageUiOption: '',
  isOnePassEnabled: 'true',
  idpDiscoveryEndpoint: '',
} as ITransactEnvVariables;

let envVariables: ITransactEnvVariables;

export const initEnvVariables = () => {
  const envConfigFromAEM = document.getElementById('env-config') as HTMLElement;
  const envConfigParsed = JSON.parse(
    envConfigFromAEM?.innerHTML ?? JSON.stringify(defaultEnvVariables)
  );

  envVariables = Object.assign({}, defaultEnvVars, envConfigParsed);
};

export const getEnvVariables = () => {
  if (!envVariables) {
    initEnvVariables();
  }

  return envVariables;
};

export const getEnvVar = (key: keyof ITransactEnvVariables): string => {
  const envVars = getEnvVariables();

  return envVars[key] || '';
};

export const getRecaptchaEnvVar = (): IRecaptchaEnvVars => {
  const {
    apigeeClientId,
    reCaptchaVerifyUrl,
    reCaptchaSiteKey,
  } = getEnvVariables();
  return {
    apiKey: apigeeClientId,
    reCaptchaVerifyUrl,
    reCaptchaSiteKey,
  };
};

export const recaptcha = {
  CART_MANIPULATION: 'cartManipulation',
  CREATE_PROPOSAL: 'createProposal',
  GET_PRODUCTPLAN: 'getProductPlan',
  CREATE_ORDERID: 'createOrderId',
  TRIGGER_LEAD: 'triggerLeadLd',
  GET_ADDONS: 'getAddons',
  GET_PROMOTIONSDETAILS: 'getPromotionDetails',
  CART_ITEMDETAILS: 'cartItemDetails',
  GET_ORGANIZATION_TYPE: 'lookup',
};

export const fetchRecaptchaToken = async (
  action: string
  // autoHideBadge = false
) => {
  const recaptchaEnvVars = getRecaptchaEnvVar();
  return await getRecaptchaToken(action, recaptchaEnvVars);
  // return await getRecaptchaToken(action, recaptchaEnvVars, autoHideBadge);
};
